import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import banner from "../assets/banner2.jpg";
import logo from "../assets/logo-kv.svg";
import berlinCenter1 from "../assets/images/berlin-center1.jpg";
import berlinCenter2 from "../assets/images/berlin-center2.jpg";
import berlinCenter3 from "../assets/images/berlin-center3.jpg";
import andijon1 from "../assets/images/andijon1.jpg";
import andijon2 from "../assets/images/andijon2.jpg";
import andijon3 from "../assets/images/andijon3.jpg";
import agrar1 from "../assets/images/agrar1.jpg";
import agrar2 from "../assets/images/agrar2.jpg";
import agrar3 from "../assets/images/agrar3.jpg";
import flyer1 from "../assets/images/flyer2.jpg";
import flyer2 from "../assets/images/flyer1.jpg";
import banner1 from "../assets/images/banner1.png";
import viza1 from "../assets/images/viza1.jpg";
import viza2 from "../assets/images/viza2.jpg";
import viza3 from "../assets/images/viza3.jpg";
import viza4 from "../assets/images/viza4.jpg";
import viza5 from "../assets/images/viza5.jpg";
import viza6 from "../assets/images/viza6.jpg";
import universitet1 from "../assets/images/universitet1.jpg";
import universitet2 from "../assets/images/universitet2.jpg";
import universitet3 from "../assets/images/universitet3.jpg";

// import mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { CardMedia } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import JNews from "../components/JNews";

// component
const News = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Box className="page_title">
          <h1>{t("news.pageTitle")}</h1>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <JNews
          title="news.title7"
          text="news.text7"
          date="29.05.2024"
          images={[viza4, viza5, viza6]}
          imageSize="4"
        />

        <JNews
          title="news.title6"
          text="news.text6"
          date="22.05.2024"
          images={[universitet1, universitet2, universitet3]}
          imageSize="4"
        />

        <JNews
          title="news.title5"
          text="news.text5"
          date="25.03.2024"
          images={[viza1, viza2, viza3]}
          imageSize="4"
        />

        <JNews
          title="news.title4"
          text="news.text4"
          date="17.03.2024"
          images={[banner1]}
          imageSize="6"
        />

        <JNews
          title="news.title2"
          text="news.text2"
          date="19.12.2023"
          images={[agrar1, agrar2, agrar3]}
          imageSize="4"
        />

        <JNews
          title="news.title1"
          text="news.text1"
          date="17.11.2023"
          images={[andijon1, andijon3, andijon2]}
          imageSize="4"
        />

        <JNews
          title="news.title"
          text="news.text"
          date="09.11.2023"
          images={[berlinCenter1, berlinCenter2, berlinCenter3]}
          imageSize="4"
        />
      </Container>
    </div>
  );
};

export default News;
