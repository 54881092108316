import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { CardMedia, Typography } from "@mui/material";

// import images
import tshirt1 from "../assets/images/tshirt1.jpg";
import tshirt2 from "../assets/images/tshirt2.jpg";
import tshirt3 from "../assets/images/tshirt3.jpg";
import tshirt4 from "../assets/images/tshirt4.jpg";

import cap1 from "../assets/images/cap1.jpg";
import cap2 from "../assets/images/cap2.jpg";
import cap3 from "../assets/images/cap3.jpg";
import cap4 from "../assets/images/cap4.jpg";

import hoody1 from "../assets/images/hoody1.jpg";
import hoody2 from "../assets/images/hoody2.jpg";
import hoody3 from "../assets/images/hoody3.jpg";
import hoody4 from "../assets/images/hoody4.jpg";

import tasche1 from "../assets/images/tasche1.jpg";
import tasche2 from "../assets/images/tasche4.jpg";
import tasche3 from "../assets/images/tasche2.jpg";
import tasche4 from "../assets/images/tasche3.jpg";

import backpack1 from "../assets/images/backpack1.jpg";
import backpack2 from "../assets/images/backpack2.jpg";
import backpack3 from "../assets/images/backpack3.jpg";
import backpack4 from "../assets/images/backpack4.jpg";

import tasse1 from "../assets/images/tasse1.jpg";
import tasse2 from "../assets/images/tasse2.jpg";
import tasse3 from "../assets/images/tasse3.jpg";
import tasse4 from "../assets/images/tasse4.jpg";

// component
const Shop = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <Box className="page_title">
          <h2>{t("shop.title")}</h2>
        </Box>

        {/* T-Shirts */}
        <Box style={{ marginBottom: "50px" }}>
          <Typography variant="h5">{t("shop.subtitle1")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tshirt1} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tshirt2} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tshirt3} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tshirt4} />
            </Grid>
          </Grid>
        </Box>

        {/* Caps */}
        <Box style={{ marginBottom: "50px" }}>
          <Typography variant="h5">{t("shop.subtitle2")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={cap1} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={cap2} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={cap3} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={cap4} />
            </Grid>
          </Grid>
        </Box>

        {/* Pullover & Hoodies */}
        <Box style={{ marginBottom: "50px" }}>
          <Typography variant="h5">{t("shop.subtitle3")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={hoody1} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={hoody2} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={hoody3} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={hoody4} />
            </Grid>
          </Grid>
        </Box>

        {/*  Taschen & Rucksäcke */}
        <Box style={{ marginBottom: "50px" }}>
          <Typography variant="h5"> {t("shop.subtitle4")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasche1} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasche2} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasche3} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasche4} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia
                component="img"
                alt=""
                height="300"
                image={backpack1}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia
                component="img"
                alt=""
                height="300"
                image={backpack2}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia
                component="img"
                alt=""
                height="300"
                image={backpack3}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia
                component="img"
                alt=""
                height="300"
                image={backpack4}
              />
            </Grid>
          </Grid>
        </Box>

        {/*  Tassen */}
        <Box style={{ marginBottom: "50px" }}>
          <Typography variant="h5"> {t("shop.subtitle5")}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasse1} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasse2} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasse3} />
            </Grid>
            <Grid item xs={6} md={3}>
              <CardMedia component="img" alt="" height="300" image={tasse4} />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Box className="section_container">
          <Box className="title">
            <div>
              {t("shop.subtitle6") + " "}
              <a
                href="mailto: shop@jas-vermittlung.de"
                target="_blank"
                rel="noreferrer"
              >
                shop@jas-vermittlung.de
              </a>
              <div></div>
            </div>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Shop;
