import React from "react";
// import { useTranslation } from "react-i18next";

// import mui
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Facebook, Instagram } from "@material-ui/icons";

// component
const SideBox = () => {
  //   const { t } = useTranslation();

  return (
    <div>
      <Box className="side_box">
        <ul>
          <li>
            <Tooltip title="Telefon" placement="left" arrow>
              <a href="tel:+4917643656708" target="_blank" rel="noreferrer">
                <PhoneIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Email" placement="left" arrow>
              <a
                href="mailto:info@jas-vermittlung.de"
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="WhatsApp" placement="left" arrow>
              <a
                href="https://wa.me/+4917643656708"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsAppIcon />
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Telegram" placement="left" arrow>
              <a
                href="https://t.me/+4917643656708"
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon />
              </a>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Telegram" placement="left" arrow>
              <a
                href="https://t.me/jasvermittlung"
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon />
              </a>
            </Tooltip>
          </li> */}
          {/* <li>
            <Tooltip title="YouTube" placement="left" arrow>
              <a
                href="https://www.youtube.com/@jas-vermittlung"
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon />
              </a>
            </Tooltip>
          </li> */}
          {/* <li>
            <Tooltip title="Instagram" placement="left" arrow>
              <a
                href="https://www.instagram.com/jasvermittlung"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </Tooltip>
          </li> */}
          {/* <li>
            <Tooltip title="Facebook" placement="left" arrow>
              <a
                href="https://www.facebook.com/profile.php?id=61550482852644"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
            </Tooltip>
          </li> */}
        </ul>
      </Box>
    </div>
  );
};

export default SideBox;
