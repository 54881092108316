import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AuthContext } from "./ContextProvider";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function JButton(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.type === "primary" ? (
        <>
          <Link
            className={
              "button_primary " + (props.className ? props.className : null)
            }
            to={props.to}
            onClick={props.onClick}
          >
            {t(props.text)}
          </Link>
        </>
      ) : (
        <Link
          className="button_secondary"
          to={props.to}
          onClick={props.onClick}
        >
          {t(props.text)}
        </Link>
      )}
    </>
  );
}
