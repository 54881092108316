import React, { useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// import mui
import Box from "@mui/material/Box";
import { AuthContext } from "../components/ContextProvider";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const mainUrl = "https://www.jas-vermittlung.de";
// const mainUrl = "http://localhost";

// Create an Axios instance with custom configurations
const api = axios.create({
  baseURL: mainUrl, // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json", // Specify the content type
  },
});

const RegisteredPeople = () => {
  const defaultMaterialTheme = createTheme();
  const navigate = useNavigate();

  const { auth } = React.useContext(AuthContext);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (auth) {
      getRegisteredPeople();
    } else {
      navigate("/login");
    }
  }, []);

  const getRegisteredPeople = () => {
    // Make a GET request
    setLoading(true);
    api
      .get("/api/students.php")
      .then((response) => {
        // Handle the API response
        // console.log("API response:", response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors, including CORS-related issues
        console.error("API request failed:", error);
        setLoading(false);
      });
  };

  const deletePerson = (data) => {
    api
      .post("/api/students.php", data.id)
      .then((response) => {
        // Handle the API response
        if (response) {
          getRegisteredPeople();
        }
      })
      .catch((error) => {
        // Handle errors, including CORS-related issues
        console.error("API request failed:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   title: "ID",
    //   editable: "never",
    // },
    {
      field: "registration_date1",
      title: "Registratsiya sanasi",
      editable: "never",
    },
    {
      field: "passport_url",
      title: "Passport",
      editable: "never",
      render: (rowData) => {
        if (
          rowData.passport_url.includes(".jpg") ||
          rowData.passport_url.includes(".jpeg") ||
          rowData.passport_url.includes(".png")
        ) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.passport_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${mainUrl}/images/${rowData.passport_url}`}
                height="100%"
                width={40}
                alt=""
                style={{ cursor: "pointer", zIndex: "999" }}
              />
            </a>
          );
        } else if (rowData.passport_url.includes(".pdf")) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.passport_url}`}
              target="_blank"
              rel="noreferrer"
            >
              rasm.pdf
            </a>
          );
        }
      },
    },
    {
      field: "firstname",
      title: "Ism",
      editable: "never",
    },
    {
      field: "lastname",
      title: "Familiya",
      editable: "never",
    },
    {
      field: "birthdate",
      title: "Tug'ilgan sana",
      editable: "never",
    },
    {
      field: "reason",
      title: "Maqsad",
      editable: "never",
    },
    {
      field: "email",
      title: "Email",
      editable: "never",
    },
    {
      field: "phone",
      title: "Telefon",
      editable: "never",
    },
    {
      field: "institution",
      title: "Universitet",
      editable: "never",
    },
    {
      field: "faculty",
      title: "Fakultet",
      editable: "never",
    },
    {
      field: "level",
      title: "Kurs",
      editable: "never",
    },
    {
      field: "german_level",
      title: "Nemis tili",
      editable: "never",
    },
    {
      field: "english_level",
      title: "Ingliz tili",
      editable: "never",
    },
    {
      field: "payment_method",
      title: "To'lov",
      editable: "never",
    },
    {
      field: "picture_url",
      title: "Rasm",
      editable: "never",

      render: (rowData) => {
        const imageClick = () => {
          console.log("Click");
        };

        if (
          rowData.picture_url.includes(".jpg") ||
          rowData.picture_url.includes(".jpeg") ||
          rowData.picture_url.includes(".png")
        ) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.picture_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${mainUrl}/images/${rowData.picture_url}`}
                height="100%"
                width={40}
                alt=""
                style={{ cursor: "pointer", zIndex: "999" }}
                onClick={imageClick}
              />
            </a>
          );
        } else if (
          rowData.picture_url.includes(".pdf") ||
          rowData.picture_url.includes(".doc") ||
          rowData.picture_url.includes(".docx")
        ) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.picture_url}`}
              target="_blank"
              rel="noreferrer"
            >
              rasm.pdf
            </a>
          );
        }
      },
    },
    {
      field: "certificate_de_url",
      title: "Sertifikat DE",
      editable: "never",

      render: (rowData) => {
        const imageClick = () => {
          console.log("Click");
        };

        if (
          rowData.certificate_de_url.includes(".jpg") ||
          rowData.certificate_de_url.includes(".jpeg") ||
          rowData.certificate_de_url.includes(".png")
        ) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.certificate_de_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${mainUrl}/images/${rowData.certificate_de_url}`}
                height="100%"
                width={40}
                alt=""
                style={{ cursor: "pointer", zIndex: "999" }}
                onClick={imageClick}
              />
            </a>
          );
        } else if (rowData.certificate_de_url.includes(".pdf")) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.certificate_de_url}`}
              target="_blank"
              rel="noreferrer"
            >
              sertifikat-En.pdf
            </a>
          );
        }
      },
    },
    {
      field: "certificate_en_url",
      title: "Sertifikat EN",
      editable: "never",

      render: (rowData) => {
        const imageClick = () => {
          console.log("Click");
        };

        if (
          rowData.certificate_en_url.includes(".jpg") ||
          rowData.certificate_en_url.includes(".jpeg") ||
          rowData.certificate_en_url.includes(".png")
        ) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.certificate_en_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${mainUrl}/images/${rowData.certificate_en_url}`}
                height="100%"
                width={40}
                alt=""
                style={{ cursor: "pointer", zIndex: "999" }}
                onClick={imageClick}
              />
            </a>
          );
        } else if (rowData.certificate_en_url.includes(".pdf")) {
          return (
            <a
              href={`${mainUrl}/images/${rowData.certificate_en_url}`}
              target="_blank"
              rel="noreferrer"
            >
              sertifikat-En.pdf
            </a>
          );
        }
      },
    },
    {
      field: "status",
      title: "Status",
    },
  ];

  return (
    <div>
      <Box style={{ minHeight: "300px", margin: "0 20px" }}>
        <Box className="section_container">
          {/* <Box className="title">
            {data ? <div>Ro'yxat</div> : <div>Ro'yxat mavjud emas!</div>}
          </Box> */}

          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              title="Talabalar ro'yxati"
              columns={columns}
              data={data}
              loading={loading}
              icons={tableIcons}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Qaytadan yuklash",
                  position: "toolbar",
                  onClick: (event, rowData) => getRegisteredPeople(),
                },
              ]}
              options={{
                // grouping: true,
                search: true,
                sorting: true,
                // actionsColumnIndex: -1,
                paging: true,
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 20, 30, 50], // rows selection options
                headerStyle: {
                  backgroundColor: "#3c5132",
                  color: "#FFF",
                },
                minBodyHeight: window.innerHeight - 300 + "px",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: "Keine Einträge",
                  addTooltip: "Hinzufügen",
                  deleteTooltip: "Löschen",
                  editTooltip: "Bearbeiten",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Diese Zeile wirklich löschen?",
                    cancelTooltip: "Abbrechen",
                    saveTooltip: "Speichern",
                  },
                },
                grouping: {
                  placeholder: "Spalten ziehen ...",
                  groupedBy: "Gruppiert nach:",
                },
                header: {
                  actions: "Aktionen",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} von {count}",
                  labelRowsSelect: "Zeilen",
                  labelRowsPerPage: "Zeilen pro Seite:",
                  firstAriaLabel: "Erste Seite",
                  firstTooltip: "Erste Seite",
                  previousAriaLabel: "Vorherige Seite",
                  previousTooltip: "Vorherige Seite",
                  nextAriaLabel: "Nächste Seite",
                  nextTooltip: "Nächste Seite",
                  lastAriaLabel: "Letzte Seite",
                  lastTooltip: "Letzte Seite",
                },
                toolbar: {
                  addRemoveColumns: "Spalten hinzufügen oder löschen",
                  nRowsSelected: "{0} Zeile(n) ausgewählt",
                  showColumnsTitle: "Zeige Spalten",
                  showColumnsAriaLabel: "Zeige Spalten",
                  exportTitle: "Export",
                  exportAriaLabel: "Export",
                  exportName: "Export als CSV",
                  searchTooltip: "Suche",
                  searchPlaceholder: "Suche",
                },
              }}
              editable={{
                // onRowUpdate: (newData, oldData) =>
                //   new Promise((resolve, reject) => {
                //     setTimeout(() => {
                //       const dataUpdate = [...data];
                //       const index = oldData.tableData.id;
                //       dataUpdate[index] = newData;
                //       setData([...dataUpdate]);

                //       resolve();
                //     }, 1000);
                //   }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...data];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setData([...dataDelete]);
                      deletePerson(oldData);

                      resolve();
                    }, 1000);
                  }),
              }}
            />
          </ThemeProvider>
        </Box>
      </Box>
    </div>
  );
};

export default RegisteredPeople;
