import React from "react";
import { useTranslation } from "react-i18next";

import banner from "../assets/banner5.jpg";

// import mui
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupsIcon from "@mui/icons-material/Groups";
import { Paper, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// component
const Achievement = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-image-full-1">
      <Box className="achievement-container">
        <Box className="black_box"></Box>
        <Box className="content_box">
          <Box style={{ textAlign: "center" }}>
            <h2 className="achievement_title">{t("success.title")}</h2>
          </Box>

          <Container maxWidth="lg">
            <Grid container spacing={5}>
              <Grid item xs={12} md={3}>
                <Card className="achievement_card">
                  <Box className="card_black_cover"></Box>
                  <Box className="card_icon_container">
                    <BusinessCenterIcon className="card_icon" />
                  </Box>
                  <Box className="card_title_container">
                    <h2>{t("success.clients")}</h2>
                  </Box>
                  <Box className="card_subtitle_container">
                    <span>56</span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card className="achievement_card">
                  <Box className="card_black_cover"></Box>
                  <Box className="card_icon_container">
                    <ThumbUpIcon className="card_icon" />
                  </Box>
                  <Box className="card_title_container">
                    <h2>{t("success.students")}</h2>
                  </Box>
                  <Box className="card_subtitle_container">
                    <span>205</span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card className="achievement_card">
                  <Box className="card_black_cover"></Box>
                  <Box className="card_icon_container">
                    <DescriptionIcon className="card_icon" />
                  </Box>
                  <Box className="card_title_container">
                    <h2>{t("success.contracts")}</h2>
                  </Box>
                  <Box className="card_subtitle_container">
                    <span>410</span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card className="achievement_card">
                  <Box className="card_black_cover"></Box>
                  <Box className="card_icon_container">
                    <GroupsIcon className="card_icon" />
                  </Box>
                  <Box className="card_title_container">
                    <h2>{t("success.team")}</h2>
                  </Box>
                  <Box className="card_subtitle_container">
                    <span>4</span>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default Achievement;
