import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import system
import users from "../users.json";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../components/ContextProvider";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AlertTitle from "@mui/material/AlertTitle";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setAuth } = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [code, setCode] = React.useState(4);
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    // getUsers();
  }, []);

  // submit
  const handleSubmit = () => {
    if (email !== "" || password !== "") {
      for (let i = 0; i < users.length; i++) {
        if (users[i].email === email && users[i].password === password) {
          setCode(1);
          setMessage(t("login.successMessage1"));
          setAuth(true);

          setTimeout(() => {
            navigate("/registered");
          }, 1000);
        } else {
          setCode(2);
          setMessage(t("login.errorMessage1"));
        }
      }
    } else {
      setCode(3);
      setMessage(t("login.errorMessage2"));
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Box className="section_container">
          <Box className="title">{t("login.login")}</Box>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={5}>
              {code !== 4 ? (
                <Alert severity={code === 1 ? "success" : "error"}>
                  <AlertTitle>
                    {code === 1
                      ? t("login.successMessage2")
                      : t("login.errorMessage3")}
                  </AlertTitle>
                  {message}
                </Alert>
              ) : null}

              <TextField
                fullWidth
                id="login-email"
                label={t("login.email")}
                style={{ margin: "20px 0" }}
                // InputProps={{
                //   style: {
                //     color: "#cbeeb1",
                //   },
                // }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                fullWidth
                id="login-pass"
                type="password"
                label={t("login.password")}
                style={{ margin: "20px 0" }}
                // InputProps={{
                //   style: {
                //     color: "#cbeeb1",
                //   },
                // }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Box className="btn_container">
                <Button
                  className="btn_contact"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("login.enter")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
