import React from "react";

import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import image1 from "../assets/images/banner11.jpg";
import image2 from "../assets/images/banner21.jpg";
import image3 from "../assets/images/banner31.jpg";
import { useTranslation } from "react-i18next";

const MyCarousel = () => {
  const { t } = useTranslation();

  var items = [
    {
      name: "Nature",
      src: image1,
      title: t("banner.title"),
      subtitle: t("banner.subtitle"),
    },
    {
      name: "Nature",
      src: image2,
      title: t("banner.title"),
      subtitle: t("banner.subtitle"),
    },
    {
      name: "Nature",
      src: image3,
      title: t("banner.title"),
      subtitle: t("banner.subtitle"),
    },
  ];

  return (
    <Carousel
      className="carousel_container"
      indicatorContainerProps={{
        style: {
          marginTop: "-25px", // 5
        },
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

function Item(props) {
  const { t } = useTranslation();

  return (
    <Box className="carousel_item_container">
      <img
        src={props.item.src}
        className="image"
        alt={props.item.name}
        title={props.item.name}
      />
      <Box className="carousel_title_container">
        <Box className="carousel_title">
          <h1>{t(props.item.title)}</h1>
          <h2>{t(props.item.subtitle)}</h2>
        </Box>
      </Box>
    </Box>
  );
}

export default MyCarousel;
