import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import mui
import Box from "@mui/material/Box";

// import flags
import FlagUz from "../assets/flag-uz.svg";
import FlagDe from "../assets/flag-de.svg";
import FlagEn from "../assets/flag-en.svg";
import FlagRu from "../assets/flag-ru.svg";

//languages
const lngs = {
  de: { name: "Deutsch", title: "De", flag: FlagDe },
  uz: { name: "O'zbek", title: "Uz", flag: FlagUz },
  en: { name: "English", title: "En", flag: FlagEn },
  ru: { name: "Русский", title: "Ру", flag: FlagRu },
};

// function
const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const [flag, setFlag] = React.useState(FlagDe);

  // useEffect, get language from localStorage
  useEffect(() => {
    const language = sessionStorage.getItem("language");

    if (language) {
      setFlag(lngs[language].flag);
      i18n.changeLanguage(language);
    } else {
      setFlag(lngs["de"].flag);
      i18n.changeLanguage("de");
    }
  }, []);

  // Handle change language
  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setFlag(lngs[lng].flag);
    sessionStorage.setItem("language", lng);
  };

  return (
    <>
      <Box className="language-selector">
        <button className="language-selector_btn">
          <img className="language_icon" src={flag} alt="Flag" title="Flag" />
        </button>

        <Box className="language-selector-content">
          {Object.keys(lngs).map((lng) => (
            <div
              key={lng}
              onClick={() => handleChangeLanguage(lng)}
              disabled={i18n.resolvedLanguage === lng}
            >
              <img
                className="language_icon"
                src={lngs[lng].flag}
                alt={lngs[lng].name}
                title={lngs[lng].name}
              />
              <span className="text">{lngs[lng].name}</span>
            </div>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default LanguagePicker;
