import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";

// import mui
import Container from "@mui/material/Container";

//component
const Imprint = () => {
  // const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <h2>Impressum</h2>
        <h3>
          <b>JAS-Vermittlung</b>
        </h3>
        <div> Geroldsauerstraße 16</div>
        <div>76534 Baden-Baden</div>
        <div>Bundesrepublik Deutschland</div>
        <h3>Geschäftsführer: Jasur Nematjonov </h3>
        <div>Handelsregister: wird aktualisiert</div>
        <div>Registergericht: wird aktualisiert</div>
        <div> USt-IdNr: 10 427 367 988</div>
        <h3>
          <b>Kontakt</b>
        </h3>
        <div>Telefon: +49 (0) 176 43 656 708</div>
        <div>Telefon: +49 (0) 722 17 714 767</div>
        <div>E-Mail: info@jas-vermittlung.de</div>
        <h3>
          <b>Verantwortlichkeit für den Inhalt</b>
        </h3>
        <div>
          Wir sind stets bemüht, die Informationen auf unserer Website aktuell
          zu halten, übernehmen jedoch keine Verantwortung für die
          bereitgestellten Inhalte. Unsere Haftung als Diensteanbieter
          beschränkt sich gemäß § 7 Abs. 1 TMG auf die Inhalte dieser
          Internetseite. Nach §§ 8-10 DSGVO sind wir nicht verpflichtet, die von
          Dritten auf unserer Website bereitgestellten oder gespeicherten Daten
          zu überwachen. Wir werden jedoch jegliche Inhalte entfernen, sobald
          uns bekannt wird, dass sie gegen das Gesetz verstoßen. In diesem Fall
          beginnt unsere Haftung ab dem Zeitpunkt, an dem uns der Verstoß
          bekannt wird.
        </div>
        <h3>
          <b>Urheberrechte</b>
        </h3>
        <div>
          Die auf dieser Webseite dargestellten Inhalte und Werke unterliegen
          dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
          Verbreitung und jede Art der Verwertung des Materials außerhalb der
          Grenzen des Urheberrechtes bedarf der vorherigen schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers.
        </div>
        <h3>
          <b>Web-Design</b>
        </h3>
        <div>www.megacodes.de / Askarjon Isroilov</div>
        <div>
          Neben unseren eigenen Bildquellen verwenden wir auf dieser Website
          Bildmotive vom Anbieter www.pexels.com und www.freepik.com/
        </div>
      </Container>
    </div>
  );
};

export default Imprint;
