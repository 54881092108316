import React from "react";
import MyCarousel from "./MyCarousel";

const Banner = (props) => {
  return (
    <div>
      <MyCarousel />
    </div>
  );
};

export default Banner;
