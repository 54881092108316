import React, { useEffect, useRef } from "react";
import axios from "axios";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import JButton from "../components/JButton";
import { useTranslation } from "react-i18next";

const mainUrl = "https://www.jas-vermittlung.de";
// const mainUrl = "http://localhost";

// Create an Axios instance with custom configurations
const api = axios.create({
  baseURL: mainUrl, // Replace with your API's base URL
  timeout: 5000, // Set a timeout value as needed
  headers: {
    "Content-Type": "multipart/form-data", // Specify the content type
  },
});

const Registration = () => {
  const { t } = useTranslation();

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [institution, setInstitution] = React.useState("");
  const [faculty, setFaculty] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [germanLevel, setGermanLevel] = React.useState("");
  const [englishLevel, setEnglishLevel] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [certificateDeUrl, setCertificateDeUrl] = React.useState("");
  const [certificateEnUrl, setCertificateEnUrl] = React.useState("");
  const [photoUrl, setPhotoUrl] = React.useState("");
  const [passportUrl, setPassportUrl] = React.useState("");
  const [code, setCode] = React.useState(4);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);

  const uploadProduct = () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("email", email);
    formData.append("birthday", birthday);
    formData.append("phone", phone);
    formData.append("institution", institution);
    formData.append("faculty", faculty);
    formData.append("level", level);
    formData.append("reason", reason);
    formData.append("germanLevel", germanLevel);
    formData.append("englishLevel", englishLevel);
    formData.append("paymentMethod", paymentMethod);
    formData.append("certificateDeUrl", certificateDeUrl);
    formData.append("certificateEnUrl", certificateEnUrl);
    formData.append("passportUrl", passportUrl);
    formData.append("photoUrl", photoUrl);

    // Make a GET request
    api
      .post("/api/registration.php", formData)
      .then((response) => {
        // Handle the API response
        // console.log("API response:", response.data);

        setCode(response.data.responseCode);

        if (response.data.responseCode === 1) {
          setMessage("Ma'lumotlar muvaffaqiyatli jo'natildi!");
          getRegisteredPeopleToday();
        } else if (response.data.responseCode === 3) {
          setMessage("Ro'yxatda mavjud!");
        } else {
          setMessage("Ma'lumotlarni jo'natishda xatolik!");
        }

        setLoading(false);
      })
      .catch((error) => {
        // Handle errors, including CORS-related issues
        console.error("API request failed:", error);
        setLoading(false);
      });

    getRegisteredPeopleToday();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("clicked!");
    if (
      firstname !== "" &&
      lastname !== "" &&
      birthday !== "" &&
      email !== "" &&
      phone !== "" &&
      institution !== "" &&
      faculty !== "" &&
      level !== "" &&
      reason !== "" &&
      germanLevel !== "" &&
      englishLevel !== "" &&
      paymentMethod !== "" &&
      passportUrl !== "" &&
      passportUrl !== null
    ) {
      uploadProduct();
    } else {
      if (firstname === "") setFirstname(null);
      if (lastname === "") setLastname(null);
      if (birthday === "") setBirthday(null);
      if (email === "") setEmail(null);
      if (phone === "") setPhone(null);
      if (institution === "") setInstitution(null);
      if (faculty === "") setFaculty(null);
      if (level === "") setLevel(null);
      if (reason === "") setReason(null);
      if (germanLevel === "") setGermanLevel(null);
      if (englishLevel === "") setEnglishLevel(null);
      if (paymentMethod === "") setPaymentMethod(null);
      if (passportUrl === "") setPassportUrl(null);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.origin !== "https://www.jas-vermittlung.de") {
      window.location.replace("https://www.jas-vermittlung.de/#/registration");
    }
  }, []);

  useEffect(() => {
    getRegisteredPeopleToday();
  }, []);

  const getRegisteredPeopleToday = () => {
    // Make a GET request
    api
      .get("/api/studentstoday.php")
      .then((response) => {
        // Handle the API response
        // console.log("API response:", response.data);
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        // Handle errors, including CORS-related issues
        console.error("API request failed:", error);
      });
  };

  const knowledgeLevel = [t("signup.no"), "A1", "A2", "B1", "B2", "C1", "C2"];
  // const paymentMethods = ["500$ naqd", "500$ viza", "500$ som"];
  const paymentMethods = [
    t("signup.payment1"),
    t("signup.payment2"),
    t("signup.payment3"),
  ];
  const levels = ["1", "2", "3", "4", t("signup.level")];
  // const reasons = [
  //   "3 oylik amaliyot (Work & Travel)",
  //   "Oliy talim amaliyoti (Fachpraktikum)",
  //   "Malaka oshirish (Weiterbildung)",
  //   "Malaka oshirish (Weiterbildung)",
  // ];
  const reasons = [
    t("signup.reason1"),
    t("signup.reason2"),
    t("signup.reason3"),
    t("signup.reason4"),
  ];

  const [germanFileName, setGermanFileName] = React.useState("");
  const [englishFileName, setEnglishFileName] = React.useState("");
  const [passFileName, setPassFileName] = React.useState("");
  const [imgFileName, setImgFileName] = React.useState("");

  // Create a reference to the hidden file input element
  const germanCertificate = useRef(null);
  const englishCertificate = useRef(null);
  const passFile = useRef(null);
  const imageFile = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClickGermanCertificate = (event) => {
    germanCertificate.current.click();
  };
  const handleClickEnglishCertificate = (event) => {
    englishCertificate.current.click();
  };
  const handleClickPassFile = (event) => {
    passFile.current.click();
  };
  const handleClickImageFile = (event) => {
    imageFile.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChangeGermanCertificate = (event) => {
    const file = event.target.files[0];
    setCertificateDeUrl(file);
    setGermanFileName(file ? file.name : null);
  };

  const handleChangeEnglishCertificate = (event) => {
    const file = event.target.files[0];
    setCertificateEnUrl(file);
    setEnglishFileName(file ? file.name : null);
  };
  const handleChangePassFile = (event) => {
    const file = event.target.files[0];
    setPassportUrl(file);
    setPassFileName(file ? file.name : null);
  };

  const handleChangeImageFile = (event) => {
    const file = event.target.files[0];
    setPhotoUrl(file);
    setImgFileName(file ? file.name : null);
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Box className="section_container">
          {data ? (
            <>
              <Box
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  paddingBottom: "20px",
                }}
              >
                {t("signup.title")}
              </Box>
              {data.map((student, i) => (
                <span>
                  {i !== 0 ? <span>, </span> : null}
                  {student.firstname} {student.lastname}
                </span>
              ))}
            </>
          ) : null}
          <Box className="title">{t("signup.title1")}</Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : null}
          {code === 1 ? (
            <>
              <Alert severity="success">
                <AlertTitle>{t("signup.title2")}</AlertTitle>
                {message}
              </Alert>
              <Box className="btn_container">
                <Button
                  className="btn_contact"
                  onClick={() => {
                    setCode(4);
                  }}
                >
                  {t("signup.title3")}
                </Button>
              </Box>
            </>
          ) : (
            <>
              {code === 2 ? (
                <Alert severity="error">
                  <AlertTitle>{t("signup.title4")}</AlertTitle>
                  {message}
                </Alert>
              ) : code === 3 ? (
                <Alert severity="error">
                  <AlertTitle>{t("signup.title5")}</AlertTitle>
                  {message}
                </Alert>
              ) : null}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} md={5}>
                  <TextField
                    fullWidth
                    required
                    id="firstname"
                    label={t("signup.field1")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setFirstname(e.target.value);
                    }}
                    error={firstname == null ? true : false}
                    helperText={firstname == null ? t("signup.helper2") : null}
                  />
                  <TextField
                    fullWidth
                    required
                    id="lastname"
                    label={t("signup.field2")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setLastname(e.target.value);
                    }}
                    error={lastname == null ? true : false}
                    helperText={lastname == null ? t("signup.helper2") : null}
                  />
                  <TextField
                    fullWidth
                    required
                    id="birthday"
                    label={t("signup.field3")}
                    type="date"
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setBirthday(e.target.value);
                    }}
                    error={birthday == null ? true : false}
                    helperText={birthday == null ? t("signup.helper2") : null}
                  />
                  <TextField
                    fullWidth
                    required
                    select
                    id="reason"
                    type="select"
                    label={t("signup.field4")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    error={reason == null ? true : false}
                    helperText={reason == null ? t("signup.helper2") : null}
                  >
                    {reasons
                      ? reasons.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    id="email"
                    type="email"
                    label={t("signup.field5")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    error={email == null ? true : false}
                    helperText={email == null ? t("signup.helper2") : null}
                  />
                  <TextField
                    fullWidth
                    required
                    id="phone"
                    label={t("signup.field6")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    error={phone == null ? true : false}
                    helperText={phone == null ? t("signup.helper2") : null}
                  />
                  <TextField
                    fullWidth
                    required
                    id="institution"
                    label={t("signup.field7")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setInstitution(e.target.value);
                    }}
                    error={institution == null ? true : false}
                    helperText={
                      institution == null ? t("signup.helper2") : null
                    }
                  />
                  <TextField
                    fullWidth
                    required
                    id="faculty"
                    label={t("signup.field8")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setFaculty(e.target.value);
                    }}
                    error={faculty == null ? true : false}
                    helperText={faculty == null ? t("signup.helper2") : null}
                  />

                  <TextField
                    fullWidth
                    required
                    select
                    id="level"
                    type="select"
                    label={t("signup.field9")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setLevel(e.target.value);
                    }}
                    error={level == null ? true : false}
                    helperText={level == null ? t("signup.helper2") : null}
                  >
                    {levels
                      ? levels.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    select
                    id="germanlevel"
                    type="select"
                    label={t("signup.field10")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setGermanLevel(e.target.value);
                    }}
                    error={germanLevel == null ? true : false}
                    helperText={
                      germanLevel == null ? t("signup.helper2") : null
                    }
                  >
                    {knowledgeLevel
                      ? knowledgeLevel.map((level, i) => (
                          <MenuItem key={i} value={level}>
                            {level}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    select
                    id="englishLevel"
                    label={t("signup.field11")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setEnglishLevel(e.target.value);
                    }}
                    error={englishLevel == null ? true : false}
                    helperText={
                      englishLevel == null ? t("signup.helper2") : null
                    }
                  >
                    {knowledgeLevel
                      ? knowledgeLevel.map((level, i) => (
                          <MenuItem key={i} value={level}>
                            {level}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>

                  <TextField
                    fullWidth
                    required
                    select
                    id="payment"
                    type="select"
                    label={t("signup.field12")}
                    style={{ margin: "20px 0" }}
                    // InputProps={{
                    //   style: {
                    //     color: "#cbeeb1",
                    //   },
                    // }}
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                    }}
                    error={paymentMethod == null ? true : false}
                    helperText={
                      paymentMethod == null ? t("signup.helper2") : null
                    }
                  >
                    {paymentMethods
                      ? paymentMethods.map((method, i) => (
                          <MenuItem key={i} value={method}>
                            {method}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>

                  <Box
                    component="fieldset"
                    className="upload-box"
                    style={{ margin: "30px 0" }}
                  >
                    <legend
                      style={{
                        color: passportUrl == null ? "#d32f2f" : "inherit",
                      }}
                    >
                      {t("signup.field13")}
                    </legend>
                    <button
                      className="btn-upload"
                      onClick={handleClickPassFile}
                    >
                      {t("signup.helper1")}
                    </button>
                    {passFileName ? <span> {passFileName}</span> : null}
                    <input
                      type="file"
                      onChange={handleChangePassFile}
                      ref={passFile}
                      style={{ display: "none" }} // Make the file input element invisible
                    />
                  </Box>
                  {passportUrl == null ? (
                    <span className="error-message">Iltimos to'ldiring!</span>
                  ) : null}

                  <Box
                    component="fieldset"
                    className="upload-box"
                    style={{ margin: "30px 0" }}
                  >
                    <legend>{t("signup.field14")}</legend>
                    <button
                      className="btn-upload"
                      onClick={handleClickImageFile}
                    >
                      {t("signup.helper1")}
                    </button>
                    {imgFileName ? <span> {imgFileName}</span> : null}
                    <input
                      type="file"
                      onChange={handleChangeImageFile}
                      ref={imageFile}
                      style={{ display: "none" }} // Make the file input element invisible
                    />
                  </Box>

                  <Box
                    component="fieldset"
                    className="upload-box"
                    style={{ margin: "30px 0" }}
                  >
                    <legend>{t("signup.field15")}</legend>
                    <button
                      className="btn-upload"
                      onClick={handleClickGermanCertificate}
                    >
                      {t("signup.helper1")}
                    </button>
                    {germanFileName ? <span> {germanFileName}</span> : null}
                    <input
                      type="file"
                      onChange={handleChangeGermanCertificate}
                      ref={germanCertificate}
                      style={{ display: "none" }} // Make the file input element invisible
                    />
                  </Box>

                  <Box
                    component="fieldset"
                    className="upload-box"
                    style={{ margin: "30px 0" }}
                  >
                    <legend>{t("signup.field16")}</legend>
                    <button
                      className="btn-upload"
                      onClick={handleClickEnglishCertificate}
                    >
                      {t("signup.helper1")}
                    </button>
                    {englishFileName ? <span> {englishFileName}</span> : null}
                    <input
                      type="file"
                      onChange={handleChangeEnglishCertificate}
                      ref={englishCertificate}
                      style={{ display: "none" }} // Make the file input element invisible
                    />
                  </Box>

                  <Box className="btn_container">
                    <JButton
                      type="primary"
                      text="signup.send"
                      onClick={handleSubmit}
                    />
                  </Box>
                </Grid>
              </Grid>
              {code === 2 ? (
                <Alert severity="error">
                  <AlertTitle>{t("signup.title4")}</AlertTitle>
                  {message}
                </Alert>
              ) : code === 3 ? (
                <Alert severity="error">
                  <AlertTitle>{t("signup.title5")}</AlertTitle>
                  {message}
                </Alert>
              ) : null}
            </>
          )}
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : null}
        </Box>
      </Container>
    </div>
  );
};

export default Registration;
