import React from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";
import { useTranslation } from "react-i18next";

const JGallery = ({ images }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Container maxWidth="lg">
        <h2 align="center">{t("home.gallery")}</h2>
        <Grid container spacing={2}>
          {images &&
            images.map((item) => (
              <Grid item xs={6} md={4}>
                <CardMedia
                  component="img"
                  alt=""
                  height="300"
                  image={item}
                  style={{ borderRadius: "20px" }}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default JGallery;
