import React, { useEffect } from "react";

import JButton from "./JButton";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

export default function JCard(props) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Card style={{ borderRadius: "20px" }}>
        <CardMedia component="img" alt="" height="250" image={props.image} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {t(props.title)}
          </Typography>
          <Typography>{t(props.subtitle)}</Typography>
        </CardContent>
        <CardActions>
          {props.to && (
            <JButton type="secondary" text="home.moreInfo" to={props.to} />
          )}
        </CardActions>
      </Card>
    </>
  );
}
