import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { CardMedia } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

export default function JNews(props) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Card variant="contained">
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography gutterBottom variant="h5" component="div">
              <b>{t(props.title)}</b>
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              {props.date}
            </Typography>
          </Stack>
          <Typography style={{ textAlign: "justify" }}>
            {t(props.text)}
          </Typography>
        </Box>
        <Box sx={{ p: 2 }} style={{ marginBottom: "100px" }}>
          <Grid container spacing={0}>
            {props.images &&
              props.images.map((image) => {
                return (
                  <>
                    <Grid md={props.imageSize}>
                      <CardMedia
                        component="img"
                        alt=""
                        // height="300"
                        image={image}
                        className="card_media"
                      />
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Box>
      </Card>
    </>
  );
}
