import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import mui
import Box from "@mui/material/Box";

//component
const NoPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Box style={{ textAlign: "center", minHeight: "300px", padding: "50px" }}>
        <Box style={{ fontSize: "100px" }}>404</Box>
        <Box style={{ fontSize: "50px" }}>{t("pageNotFound")}</Box>
      </Box>
    </div>
  );
};

export default NoPage;
