import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import contact from "../assets/contact.jpg";

// import mui
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Banner from "../components/Banner";
import { Typography } from "@mui/material";

// component
const Contact = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <Box>
        <Banner
          src={contact}
          imageName="Restaurant"
          title="banner.title"
          subtitle="banner.subtitle"
          nextContainerId="contact"
        />
      </Box> */}
      <Container id="contact" maxWidth="lg" className="contact_container">
        <Box className="page_title">
          <Box className="contact_title">
            <span>{t("contact.title")}</span>
          </Box>
        </Box>

        <Typography
          variant="h4"
          align="center"
          style={{ marginBottom: "20px" }}
        >
          {t("contact.inDe")}
        </Typography>

        <Grid container direction="row" justifyContent="center" spacing={5}>
          <Grid item>
            <Box className="contact_box">
              <h2>
                <AccountBoxIcon className="contact_icon" /> Jasur Nematjonov
              </h2>
              <h4>
                <PhoneIcon className="contact_icon" />
                <a href="tel:+4917643656708">+49 (0) 176 43 656 708</a>
              </h4>
              <h4>
                <PhoneIcon className="contact_icon" />
                <a href="tel:+4972217714767">+49 (0) 722 17 714 767</a>
              </h4>
              <h4>
                <EmailIcon className="contact_icon" />
                <a href="mailto:info@jas-vermittlung.de">
                  info@jas-vermittlung.de
                </a>
                {/* <a href="mailto:n.jasur@web.de">n.jasur@web.de</a> */}
              </h4>
            </Box>
          </Grid>
          {/* <Grid item>
            <Box className="contact_box">
              <h2>
                <AccountBoxIcon className="contact_icon" /> Behzod Yuldashev
              </h2>
              <h4>
                <PhoneIcon className="contact_icon" />
                <a href="tel:+491782454278">+49 (0) 178 24 542 78</a>
              </h4>
              <h4>
                <EmailIcon className="contact_icon" />
                <a href="mailto:info@jas-vermittlung.de">
                  info@jas-vermittlung.de
                </a>
              </h4>
            </Box>
          </Grid> */}

          <Grid item>
            <Box className="contact_box">
              <h2>{t("contact.address")}</h2>
              {/* <h4>JAS Vermittlung</h4>
              <h4> Geroldsauerstraße 16</h4>
              <h4>76534 Baden-Baden</h4> */}
              <h4>{t("contact.address11")}</h4>
              <h4>{t("contact.address12")}</h4>
              <h4>{t("contact.address13")}</h4>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h4" align="center" style={{ margin: "30px 0" }}>
          {t("contact.inUz")}
        </Typography>

        <Grid container direction="row" justifyContent="center" spacing={5}>
          <Grid item>
            <Box className="contact_box">
              <h2>
                <AccountBoxIcon className="contact_icon" />
                Sultonbek Qosimov
              </h2>
              <h4>
                <PhoneIcon className="contact_icon" />
                <a href="tel:+998994654815">+998 99 4654815</a>
              </h4>
              <h4>
                <EmailIcon className="contact_icon" />
                <a href="mailto:sultonbek_germany@mail.ru">
                  sultonbek_germany@mail.ru
                </a>
              </h4>
            </Box>
          </Grid>
          <Grid item>
            <Box className="contact_box">
              <h2>{t("contact.address")}</h2>
              {/* <h4>JAS DEUTSCH o'quv markazi</h4>
              <h4>207 - xona</h4>
              <h4>AQXAI GEM binosi</h4> */}
              <h4>{t("contact.address21")}</h4>
              <h4>{t("contact.address22")}</h4>
              <h4>{t("contact.address23")}</h4>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Contact;
