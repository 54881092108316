import React, { useEffect } from "react";
import girl from "../assets/student6.jpg";
import { useTranslation } from "react-i18next";

// import mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import JButton from "../components/JButton";
import Container from "@mui/material/Container";
import CheckCircle from "@mui/icons-material/CheckCircle";

// component
const Services = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Box id="service" className="main_container">
        <Container maxWidth="lg">
          <Box className="section_container">
            <Box className="page_title">
              <Box className="title">
                <div>{t("services.list.title")}</div>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <div className="list_container">
                  <ul>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text1")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text2")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text3")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text4")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text6")}
                    </li>
                    <li>
                      <CheckCircle className="list_icon" />
                      {t("services.list.text7")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={4}>
                <Box className="img_container">
                  <img src={girl} alt="student" title="student" />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="btn_container">
            <JButton type="primary" text="banner.contact" to="/contact" />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Services;
