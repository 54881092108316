import React from "react";
import { HashRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import ContextProvider from "./components/ContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <HashRouter>
        <ContextProvider>
          <App />
        </ContextProvider>
      </HashRouter>
    </React.Suspense>
  </React.StrictMode>
);

reportWebVitals();
