import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";

// import mui
import Container from "@mui/material/Container";

//component
const DataProtection = () => {
  // const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container maxWidth="lg">
        <div>
          <h2>Datenschutzerklärung</h2> <h3>1. Datenschutz auf einen Blick</h3>
          <h4>Allgemeine Hinweise</h4>
          <div>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </div>
          <h4>Datenerfassung auf dieser Website</h4>
          <h5>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </h5>
          <div>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </div>
          <h4>Wie erfassen wir Ihre Daten?</h4>
          <div>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch oder
            nach Ihrer Einwilligung beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
          </div>
          <h4>Wofür nutzen wir Ihre Daten?</h4>
          <div>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </div>
          <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
          <div>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </div>
          <h4>Analyse-Tools und Tools von Drittanbietern</h4> Beim Besuch dieser
          Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
          geschieht vor allem mit sogenannten Analyseprogrammen. Die Analyse
          Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten
          kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse
          widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
          verhindern. Details hierzu entnehmen Sie unserer Datenschutzerklärung
          unter der Überschrift “Drittmodule und Analysetools”.
          <h3>2. Allgemeine Hinweise und Pflichtinformationen</h3>
          <h4>Datenschutz </h4>
          <div>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z. B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </div>
          <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung </h4>
          <div>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </div>
          <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde </h4>
          <div>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
          </div>
          <h4>Recht auf Datenübertragbarkeit </h4>
          <div>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </div>
          <h4>SSL- bzw. TLS-Verschlüsselung</h4>
          <div>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
            bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen
            Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </div>
          <h4>Auskunft, Löschung und Berichtigung </h4>
          <div>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
            unter der im Impressum angegebenen Adresse an uns wenden.
          </div>
          <h4> Widerspruch gegen Werbe-E-Mails </h4>
          <div>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
            Werbung und Informationsmaterialien wird hiermit widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-E-Mails, vor.
          </div>
          <h3>3. Datenerfassung auf unserer Website</h3> <h4>Cookies</h4>
          <div>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt. Cookies haben verschiedene
            Funktionen. Zahlreiche Cookies sind technisch notwendig, da
            bestimmte Websitefunktionen ohne diese nicht funktionieren würden
            (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
            Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
            anzuzeigen. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
            bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies,
            z. B. für die Warenkorbfunktion) oder zur Optimierung der Website
            (z.B. Cookies zur Messung des Webpublikums) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
            sofern keine andere Rechtsgrundlage angegeben wird. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
            Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
            betreffenden Cookies ausschließlich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
            jederzeit widerrufbar. Sie können Ihren Browser so einstellen, dass
            Sie über das Setzen von Cookies informiert werden und Cookies nur im
            Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
            oder generell ausschließen sowie das automatische Löschen der
            Cookies beim Schließen des Browsers aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein.
          </div>
          <h4>Server-Log-Dateien</h4>
          <div>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind: Browsertyp und
            Browserversion verwendetes Betriebssystem Referrer URL Hostname des
            zugreifenden Rechners Uhrzeit der Serveranfrage IP-Adresse Eine
            Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website –hierzu müssen die Server-Log-Files
            erfasst werden.
          </div>
          <h4>Kontaktformular</h4>
          <div>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten
            erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
            Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
            übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
            Interesse an der effektiven Bearbeitung der an uns gerichteten
            Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde. Die von
            Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
            gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen –
            bleiben unberührt.
          </div>
          <h4>Anfrage per E-Mail, Telefon oder Telefax </h4>
          <div>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
            beruht die Verarbeitung auf unserem berechtigten Interesse an der
            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs.
            1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) sofern diese abgefragt wurde. Die von Ihnen an uns per
            Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns
            zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen – insbesondere gesetzliche
            Aufbewahrungsfristen – bleiben unberührt.
          </div>
          <h3>4. Soziale Medien</h3>
          <h4>Social-Media-Plugins mit Shariff </h4>
          <div>
            Auf dieser Website werden Plugins von sozialen Medien verwendet (z.
            B. Facebook, Twitter, Instagram, Pinterest, XING, LinkedIn, Tumblr).
            Die Plugins können Sie in der Regel anhand der jeweiligen
            Social-Media-Logos erkennen. Um den Datenschutz auf dieser Website
            zu gewährleisten, verwenden wir diese Plugins nur zusammen mit der
            sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die
            auf dieser Website integrierten Plugins Daten schon beim ersten
            Betreten der Seite an den jeweiligen Anbieter übertragen. Erst wenn
            Sie das jeweilige Plugin durch Anklicken der zugehörigen
            Schaltfläche aktivieren, wird eine direkte Verbindung zum Server des
            Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin
            aktivieren, erhält der jeweilige Anbieter die Information, dass Sie
            mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie
            gleichzeitig in Ihrem jeweiligen Social-Media-Account (z. B.
            Facebook) eingeloggt sind, kann der jeweilige Anbieter den Besuch
            dieser Website Ihrem Benutzerkonto zuordnen. Das Aktivieren des
            Plugins stellt eine Einwilligung im Sinne des Art. 6 Abs. 1 lit. a
            DSGVO dar. Diese Einwilligung können Sie jederzeit mit Wirkung für
            die Zukunft widerrufen.
          </div>
          <h3>5. Analyse-Tools und Werbung</h3>
          <h4> Google Analytics</h4>
          <div>
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA. Google Analytics verwendet so genannte
            „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert
            werden und die eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
            Benutzung dieser Website werden in der Regel an einen Server von
            Google in den USA übertragen und dort gespeichert. Die Speicherung
            von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1
            lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
            seine Werbung zu optimieren.
          </div>
          <h4>Browser Plugin</h4>
          <div>
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch den Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:
            https://tools.google.com/dlpage/gaoptout?hl=de.
          </div>
          <h4>Widerspruch gegen Datenerfassung</h4>
          <div>
            Sie können die Erfassung Ihrer Daten durch Google Analytics
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
            zukünftigen Besuchen dieser Website verhindert: Google Analytics
            deaktivieren. Mehr Informationen zum Umgang mit Nutzerdaten bei
            Google Analytics finden Sie in der Datenschutzerklärung von Google:
            https://support.google.com/analytics/answer/6004245?hl=de.
          </div>
          <h4>Quelle:</h4>
          <div>
            https://www.e-recht24.de, Sören Siebert (Rechtsanwalt für
            Internetrecht)
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DataProtection;
